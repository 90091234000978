.slider {
  position: fixed;
  top: 5vh;
  left: 0vw;
  height: 90vh;
  width: 100vw;
  margin: 0 auto;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 201;
  }
  
  .image {
    margin: 0 auto;
    width: 80vw;
    max-height: 90vh;
    z-index: 211;
    box-sizing: border-box;
    border: 5px rgba(10, 57, 57, 0.933) solid;
    border-radius: 15px;
  }
  
  .right-arrow {
    position: fixed;
    top: 46vh;
    right: 0.5vw;
    width: 7vw;
    border: 5px solid rgba(24, 158, 158, 0.933);
    height: 7vh;
    padding: 0.5vh 0.5vw;
    border-radius: 70px;
    z-index: 221;
    background-color: transparent;
    cursor: pointer;
  }
  .left-arrow {
    position: fixed;
    top: 46vh;
    left: 0.5vw;
    width: 7vw;
    border: 5px solid rgba(24, 158, 158, 0.933);
    height: 7vh;
    padding: 0.5vh 0.5vw;
    border-radius: 70px;
    z-index: 221;
    background-color: transparent;
    cursor: pointer;
  }
  .right-arrow:hover, 
  .left-arrow:hover {
    background-color: rgba(5.1, 23.5, 23.5, 0.933);
}

  .slide {
    transform: translateY(-110vh);
    transition-duration: 0.5s;
  }
  
  .slide.active {
    transform: translateY(0);
    transition-duration: 0.5s;
  }

  @media (max-width: 600px) {
    .left-arrow,
    .right-arrow {
      border: 3px;
    }
    .image {
      margin: 0 auto;
      width: 75vw;
      max-height: 90vh;
      z-index: 211;
      box-sizing: border-box;
      border: 3px rgba(10, 57, 57, 0.933) solid;
      border-radius: 15px;
    }
}