.pWord {
    font-family: "EnchantedLand";
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 74vw;
    font-size: 5vh;
    font-weight: 400;
}
.sWord {
    font-family: "EnchantedLand";
    margin: 0 auto;
    position: absolute;
    top: 3vh;
    left: 74.7vw;
    font-size: 5vh;
    font-weight: 400;
}



@media (max-width: 1400px) {
    .pWord {
        top: 0;
        font-size: 4.5vh;
    }
    .sWord {
        /* top: 4vh; */
        font-size: 4.5vh;
    }
}
@media (max-width: 1200px) {
    .pWord {
        top: 0;
        /* font-size: ; */
    }
    .sWord {
        /* top: 5.7vh; */
        left: 75.5vw;
        /* font-size: ; */
    }
}
@media (max-width: 850px) {
    .pWord {
        top: 0;
        /* font-size: ; */
    }
    .sWord {
        top: 3.5vh;
        /* font-size: ; */
    }
}

@media (max-width: 600px) {
    .pWord {
        top: 0.5vh;
        font-size: 4vh;
    }
    .sWord {
        top: 3.5vh;
        font-size: 4vh;
        left: 76.5vw;
    }
}
@media (max-width: 300px) {
    .pWord {
        top: 0.5vh;
        /* font-size: ; */
    }
    .sWord {
        top: 3vh;
        /* font-size: ; */
    }
}