.headerUpper {
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: linear-gradient(rgba(3, 12, 12, 0.933), rgba(3, 12, 12, 0.933)),url(../img/IMGP7571-3.jpg) no-repeat;
    background-size: cover;
    width: 100vw;
}

.headerUpper__left {
    margin: 0 auto;
    padding: 0 2vw;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.headerUpper__left h1 {
    font-size: 2vh;
    margin: auto;
    
}

.pWord {
    font-family: "EnchantedLand";
    margin: 0 auto;
    position: absolute;
    top: 0vh;
    left: 75vw;
    font-size: 5vh;
    font-weight: 400;
}
.sWord {
    font-family: "EnchantedLand";
    margin: 0 auto;
    position: absolute;
    top: 3vh;
    left: 75.7vw;
    font-size: 5vh;
    font-weight: 400;
}

@media (max-width: 600px) {
    .headerUpper__left h1 {
        font-size: 1.7vh;
        line-height: 2.3vh;
    }
    .pWord {
        font-size: 4vh;
        top: 1vh;
    }
    .sWord {
        font-size: 4vh;
        top: 3.5vh;
        left: 77vw;
    }
}
