.menu_main {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    height: 5vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    margin: 0 auto;
    background-color: rgba(3, 10, 10, 0.933);
    border-bottom: snow 1px solid;
    border-top: snow 1px solid;
    border-radius: 100px;
}

.menu_main li {
    list-style: none;
    color: rgba(24, 158, 158, 0.933);
    font-size: 2.5vh;
    margin: 0 auto;
    padding: 0.5vh 1vw;
    cursor: pointer;
    text-decoration: none;
}

.menuMain__links {
    color: rgba(24, 158, 158, 0.933);
    font-size: 2.5vh;
    margin: 0 auto;
    padding: 0.5vh 1vw;
    list-style: none;
    cursor: pointer;
    text-decoration: none;
}
.menuMain__links:hover {
    color: snow;
    background-color: rgba(5, 29, 29, 0.933);
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

@media (max-width: 1200px) {
    .menuMain__links {
        font-size: 1.2rem;
        letter-spacing: 0.1vw;
    }
}

@media (max-width: 600px) {
    .menuMain__links {
        font-size: 0.9rem;
        letter-spacing: 0.1vw;
    }
    .menuMain__links {
        padding: 1vh 2.2vw;
    }
}
@media (max-width: 300px) {
    .menuMain__links {
        font-size: 0.7rem;
        letter-spacing: 0.1vw;
    }
}