.pictureSwitchRight {
    position: fixed;
    top: 46vh;
    right: 0.5vw;
    width: 7vw;
    border: 5px solid rgba(24, 158, 158, 0.933);
    height: 7vh;
    padding: 0.5vh 0.5vw;
    border-radius: 70px;
    z-index: 221;
    background-color: transparent;
    cursor: pointer;
}
.pictureSwitchRight:hover {
    background-color: rgba(5.1, 23.5, 23.5, 0.933);
}

@media (max-width: 600px) {
    .pictureSwitchRight {
        border: 2px solid rgba(24, 158, 158, 0.933);
        width: 7vw; height: 7vh;
        right: 1vw;
    }
}