.footer {
    width: 100%;
    height: 7vh;
    position: relative;
    bottom: 0;
    left: 0;
    margin: auto;/* 
    margin-top: 7vh; */
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.link {
    color: rgba(24, 158, 158, 0.933);
    text-decoration: none;
    cursor: pointer;
    padding: 1rem;
    margin: 0;
    background-color: rgba(0, 0, 139, 0);
    border-style: none;
    font-size: 1vh;
}
.link:hover {
    color: snow;
    background-color: rgba(5, 29, 29, 0.933);
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.footer_main {
    width: 100vw;
    height: 5vh;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: darkcyan;
}