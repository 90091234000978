.PGallerySelection_main {
    position: relative;
    width: 90vw;
    margin: 2.5vh auto;
    padding: 2vh 0vw;
    padding-bottom: 3vh;
    background: linear-gradient(rgba(5.1, 23.5, 23.5, 0.933), rgba(5.1, 23.5, 23.5, 0.933)),url(../img/IMGP7571-3.jpg) no-repeat;
    background-size: cover;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.PGallerySelection_main img {
    height: 30vh;
    margin: 2vh 2vw;
    box-shadow: 7px 8px 0 rgba(0, 0, 0, 0.226), 0 6px 20px 0 rgba(0, 0, 0, 0.384);
    cursor: pointer;
    background-color: transparent;
    border-radius: 15px;
    -webkit-tap-highlight-color: transparent;
}
/* .PGallerySelection_main div {
    height: 30vh;
    margin: 2vh 2vw;
    box-shadow: 7px 8px 0 rgba(0, 0, 0, 0.226), 0 6px 20px 0 rgba(0, 0, 0, 0.384);
    cursor: pointer;
    background-color: transparent;
    border-radius: 15px;
    -webkit-tap-highlight-color: transparent;
} */

.PGallerySelection_main img {
    background-size: cover;
    height: 30vh;
    border-radius: 15px;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
}
.PGallerySelection_main img:hover {
    transform: scale(101%);
}
img:focus-visible {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
img:active {
    -webkit-tap-highlight-color: transparent;
}
.pictureContainer {
    position: fixed;
}

.picture30Select__wrapper {
    position: fixed;
    top: -86vh;
    left: 0vw;
    height: 80vh;
    width: 100vw;
    margin: 0 auto;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 201;
}

.pictureViewersPicture {
    margin: 0 auto;
    width: 80vw;
    max-height: 90vh;
    z-index: 211;
    box-sizing: border-box;
    border: 5px rgba(10, 57, 57, 0.933) solid;
    border-radius: 15px;
}
.pictureViewersPicture__Speshul18 {
    max-width: 80vw;
    width: 33vw;
}

/* .PGallerySelection__Box1 {
    width: 25vw;
}
.PGallerySelection__Box2 {
    width: 32vw;
} */
.PGallerySelection__Box3 {/* 6 */
    /* width: 32rem; */
    width: 47vh;
}
/* .PGallerySelection__Box4 {
    width: 25vw;
}
.PGallerySelection__Box5 {
    width: 24vw;
} */
.PGallerySelection__Box6 {/* 4 */
    width: 44vh;
}
.PGallerySelection__Box7 {/* 7 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGallerySelection__Box8 {
    width: 25vw;
}
.PGallerySelection__Box9 {
    width: 21vw;
}
.PGallerySelection__Box10 {
    width: 25vw;
}
.PGallerySelection__Box11 {
    width: 25vw;
}
.PGallerySelection__Box12 {
    width: 25vw;
} */
.PGallerySelection__Box13 {/* 8 */
    /* width: 30rem; */
    width: 44vh;
}
.PGallerySelection__Box14 {/* 9 */
    /* width: 30rem; */
    width: 44vh;
}
.PGallerySelection__Box15 {/* 10 */
    /* width: 30rem; */
    width: 44vh;
}
.PGallerySelection__Box16 { /* 1 */
    width: 43vh;
    /* width: 30rem; */
}
/* .PGallerySelection__Box17 {
    width: 25vw;
    background-color: darkgrey;
}
 */
.PGallerySelection__Box18 {/* 2 */
    /* width: 13rem; */
    width: 20vh;
}
.PGallerySelection__Box19 {/* 3 */
    /* width: 30rem; */
    width: 45vh;
}
.PGallerySelection__Box20 {/* 11 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGallerySelection__Box21 {
    width: 11vw;
    background-color: wheat;
} */
.PGallerySelection__Box22 {/* 5 */
    width: 72vh;
}
.PGallerySelection__Box23 {/* 22 */
    /* width: 36rem; */
    width: 53vh;
}
.PGallerySelection__Box24 {/* 18 */
    /* width: 38rem; */
    width: 57vh;
}
/* .PGallerySelection__Box25 {
    width: 25vw;
    background-color: thistle;
} */
.PGallerySelection__Box26 {/* 12 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGallerySelection__Box27 {
    width: 25vw;
    background-color: turquoise;
} */
.PGallerySelection__Box28 {/* 13 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGallerySelection__Box29 {
    width: 26vw;
    background-color: chocolate;
} */
/* .PGallerySelection__Box30 {
    width: 25vw;
    background-color: aquamarine;
} */
.PGallerySelection__Box31 {/* 14 */
    /* width: 30rem; */
    width: 44vh;
}
.PGallerySelection__Box32 {/* 15 */
    /* width: 30rem; */
    width: 44vh;
}
.PGallerySelection__Box33 {/* 16 */
    /* width: 30rem; */
    width: 44vh;
}
.PGallerySelection__Box34 {/* 17 */
    /* width: 28.5rem; */
    width: 42vh;
}
.PGallerySelection__Box35 {/* 19 */
    /* width: 32rem; */
    width: 48vh;
}
.PGallerySelection__Box36 {/* 20 */
    /* width: 31rem; */
    width: 47vh;
}
.PGallerySelection__Box37 {/* 21 */
    /* width: 31rem; */
    width: 46vh;
}

@media (max-width: 600px) {
    .PGallerySelection__Box22 {
        display: none;
    }
    .pictureViewersPicture {
        width: 75vw;
        max-height: 90vh;
        border: 2px rgba(10, 57, 57, 0.933) solid;
    }
    .PGallerySelection_main img {
        height: 24vh;
    }
    .PGallerySelection_main .PGallerySelection__Box23 {
        width: 81vw;
    }
    .PGallerySelection__Box24 {
        width: 80vw;
    }
    .PGallerySelection_main div {
        width: 76vw;
    }
    .PGallerySelection_main {
        display: flex;
        flex-direction:column;
        align-items: center;
    }
}
@media (max-width: 300px) {
    /* .PGallerySelection_main div {
        height: 20vh;
    } */
    .PGallerySelection_main img {
        height: 22vh;
    }
    .PGallerySelection_main .PGallerySelection__Box23 {
        width: 81vw;
    }
    .PGallerySelection__Box24 {
        width: 81vw;
    }
    .PGallerySelection_main div {
        width: 76vw;
    }
    .PGallerySelection_main {
        display: flex;
        flex-direction:column;
        align-items: center;

    }
}