body {
  margin: 0;
  font-family:  "lumiera", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  letter-spacing: 0.1rem;
  /* scrollbar-width: 1vw; */
  background-color: rgb(26, 26, 26);
  width: 100vw;
  box-sizing: border-box;
}

code {
  font-family: "lumiera", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  src: local("lumiera"), url("./fonts/Lumiera.woff");
  font-family: "Lumiera";
}
@font-face {
  src: local("enchanted"), url("./fonts/EnchantedLand.otf");
  font-family: "EnchantedLand";
}

@media (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 7px;
    overflow-y: scroll;
    box-sizing: border-box;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(12, 56, 56, 0.933);
    outline: 1px solid rgba(2, 8, 8, 0.933);
    overflow-y: scroll;
    box-sizing: border-box;
  }
}
input,
textarea,
button,
select,
a
img {
    -webkit-tap-highlight-color: transparent;
}