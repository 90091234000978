.PGalleryThematic_main {
    position: relative;
    top: -3vh;
    width: 90vw;
    margin: 2.5vh auto;
    padding: 2vh 1vw;
    padding-bottom: 3vh;
    background-size: cover;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.PGalleryThematic_main img {
    height: 30vh;
    margin: 2vh 2vw;
    box-shadow: 7px 8px 0 rgba(0, 0, 0, 0.226), 0 6px 20px 0 rgba(0, 0, 0, 0.384);
    cursor: pointer;
    background-color: transparent;
    border-radius: 15px;
    -webkit-tap-highlight-color: transparent;
    background-size: cover;
}

.PGalleryThematic_main img:hover {
    transform: scale(101%);
}
img:focus-visible {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
img:active {
    -webkit-tap-highlight-color: transparent;
}
.pictureContainer {
    position: fixed;

}

.picture30Select__wrapper {
    position: fixed;
    top: -86vh;
    left: 0vw;
    height: 80vh;
    width: 100vw;
    margin: 0 auto;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 201;
}

.pictureViewersPicture {
    margin: 0 auto;
    width: 80vw;
    max-height: 90vh;
    z-index: 211;
    box-sizing: border-box;
    border: 3px rgba(10, 57, 57, 0.933) solid;
    border-radius: 15px;
}
.pictureViewersPicture__Speshul18 {
    max-width: 80vw;
    width: 33vw;
}
/* .PGalleryThematic__Box1 {
    width: 25vw;
}
.PGalleryThematic__Box2 {
    width: 32vw;
} */
.PGalleryThematic__Box3 {/* 6 */
    /* width: 32rem; */
    width: 47vh;
}
/* .PGalleryThematic__Box4 {
    width: 25vw;
}
.PGalleryThematic__Box5 {
    width: 24vw;
} */
.PGalleryThematic__Box6 {/* 4 */
    width: 44vh;
}
.PGalleryThematic__Box7 {/* 7 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGalleryThematic__Box8 {
    width: 25vw;
}
.PGalleryThematic__Box9 {
    width: 21vw;
}
.PGalleryThematic__Box10 {
    width: 25vw;
}
.PGalleryThematic__Box11 {
    width: 25vw;
}
.PGalleryThematic__Box12 {
    width: 25vw;
} */
.PGalleryThematic__Box13 {/* 8 */
    /* width: 30rem; */
    width: 44vh;
}

.PGalleryThematic__Box14 {/* 9 */
    /* width: 30rem; */
    width: 44vh;
}
.PGalleryThematic__Box15 {/* 10 */
    /* width: 30rem; */
    width: 44vh;
}
.PGalleryThematic__Box16 { /* 1 */
    width: 43vh;
    /* width: 30rem; */
}
/* .PGalleryThematic__Box17 {
    width: 25vw;
    background-color: darkgrey;
}
 */
.PGalleryThematic__Box18 {/* 2 */
    /* width: 13rem; */
    width: 20vh;
}
.PGalleryThematic__Box19 {/* 3 */
    /* width: 30rem; */
    width: 45vh;
}
.PGalleryThematic__Box20 {/* 11 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGalleryThematic__Box21 {
    width: 11vw;
    background-color: wheat;
} */
.PGalleryThematic__Box22 {/* 5 */
    width: 72vh;
}
.PGalleryThematic__Box23 {/* 22 */
    /* width: 36rem; */
    width: 53vh;
}
.PGalleryThematic__Box24 {/* 18 */
    /* width: 38rem; */
    width: 57vh;
}
/* .PGalleryThematic__Box25 {
    width: 25vw;
    background-color: thistle;
} */
.PGalleryThematic__Box26 {/* 12 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGalleryThematic__Box27 {
    width: 25vw;
    background-color: turquoise;
} */
.PGalleryThematic__Box28 {/* 13 */
    /* width: 30rem; */
    width: 44vh;
}
/* .PGalleryThematic__Box29 {
    width: 26vw;
    background-color: chocolate;
} */
/* .PGalleryThematic__Box30 {
    width: 25vw;
    background-color: aquamarine;
} */
.PGalleryThematic__Box31 {/* 14 */
    /* width: 30rem; */
    width: 44vh;
}
.PGalleryThematic__Box32 {/* 15 */
    /* width: 30rem; */
    width: 44vh;
}
.PGalleryThematic__Box33 {/* 16 */
    /* width: 30rem; */
    width: 44vh;
}
.PGalleryThematic__Box34 {/* 17 */
    /* width: 28.5rem; */
    width: 42vh;
}
.PGalleryThematic__Box35 {/* 19 */
    /* width: 32rem; */
    width: 48vh;
}
.PGalleryThematic__Box36 {/* 20 */
    /* width: 31rem; */
    width: 47vh;
}
.PGalleryThematic__Box37 {/* 21 */
    /* width: 31rem; */
    width: 46vh;
}

@media (max-width: 600px) {
    .PGalleryThematic_main .PGalleryThematic__Box {
        height: 25vh;
    }
    .PGalleryThematic__Box22 {
        width: 84vw;
    }
    .PGalleryThematic__Box23 {
        width: 84vw;
    }
    .PGalleryThematic__Box24 {
        width: 84vw;
        
    }
}
@media (max-width: 300px) {
    .PGalleryThematic__Box {
        height: 20vh;
    }
    .PGalleryThematic_main__Box22 img {
        display: none;
    }
}
