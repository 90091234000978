.closeXMain {
    height: 5rem;
    width: 5rem;
    background-image: url("../img/vector-cross.png");
    background-size: cover;
    position: fixed;
    right: 2.5vw;
    top: 2.5vh;
    z-index: 201;
    cursor: pointer;
    background-color: rgba(24, 158, 158, 0.933);
    transform: translateX(-700%);
    border-radius: 50px;
}

.closeXMain:hover {
    background-color: rgb(189, 1, 1);
    transform: scale(1.1);
}
.closeXMain:active {
    background-color: rgb(0, 255, 247);
}
@media (max-width: 600px) {
    .closeXMain {
        height: 3rem;
        width: 3rem;
        right: 5vw;
        top: 25vh;
    }
}