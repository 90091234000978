.contactMain {
    position: relative;
    /* top: 16vh; */
    width: 90vw;
    height: 70vh;
    margin: 2.5vh auto;
    padding: 1vh 0vw;
    background: linear-gradient(rgba(5.1, 23.5, 23.5, 0.933), rgba(5.1, 23.5, 23.5, 0.933)),url(../img/IMGP7571-3.jpg) no-repeat;
    background-size: cover;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.contactUnvisibleblock1,
.contactUnvisibleblock2 {
    box-sizing: border-box;
    width: 50%;
    margin: 0;
}
.contactUnvisibleblock2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 3vh 3vw;
}
.contactUnvisibleblock2__icons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contactUnvisibleblock1 {
    max-width: 30vw;
    margin: auto;
}
.contactMain input,
.contactMain textarea {
    background-color: rgba(5.1, 23.5, 23.5, 0.933);
    color: whitesmoke;
    border: none;
    border-radius: 20px;
    outline: none;
}
.contactMain textarea {
    padding: 0.5vh 0.5vw;
    box-sizing: border-box;
}
.contactMain input:focus-visible,
.contactMain textarea:focus-visible {
    border-color: red;
}

.formContactMessageTextarea {
    margin-top: 0.3vh;
    width: 100%;
    max-width: 100%;
    height: 13vh;
    max-height: 20vh;
    border-radius: 5px;
    font-size: 1.2rem;
}
.contactFormButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    width: 100%;
    background-color: linear-gradient(rgba(5.1, 23.5, 23.5, 0.933), rgba(5.1, 23.5, 23.5, 0.933)),url(../img/IMGP7571-3.jpg) no-repeat;
}
.contactFormButtons button {
    background: transparent;
    font-size: 1.5rem;
    border-radius: 5px;
    color: whitesmoke;
    padding: 2vh 2vw;
    cursor: pointer;
    border: none;
}
.contactFormButtons button:hover {
    background-color: rgba(5, 24, 24, 0.77);
    border-radius: 200px;
    color: #b08807;
}

.contactUnvisibleblock2 {
    padding-bottom: 3vh;
    text-align: center;
    border-left: rgb(127, 99, 5) 7px solid;
}
.picPlus__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contactUnvisibleblock2 .picPlus {
    width: 25vw;
    border-radius: 50px;
    margin: 0 auto;
    filter: brightness(80%);
}
.contactUnvisibleblock2__icon_iconsSry {
    padding-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
}
.contactUnvisibleblock2 .fac,
.contactUnvisibleblock2 .inst,
.contactUnvisibleblock2 .github,
.contactUnvisibleblock2 .twitter,
.contactUnvisibleblock2 .linkedin {
    width: 4.5vw;
    border-radius: 170px;
    cursor: pointer;
}
.contactUnvisibleblock2 .fac:hover,
.contactUnvisibleblock2 .inst:hover,
.contactUnvisibleblock2 .twitter:hover,
.contactUnvisibleblock2 .github:hover,
.contactUnvisibleblock2 .linkedin:hover {
    background: linear-gradient(rgba(5.1, 23.5, 23.5, 0.933), rgba(5.1, 23.5, 23.5, 0.933)) no-repeat;
    filter: invert(100%);
}

/* .contactUnvisibleblock2 .github,
.contactUnvisibleblock2 .twitter {
    border-radius: 200px;
} */

@media (max-width: 1200px) {
    .contactUnvisibleblock1,
    .contactUnvisibleblock2__icons__h1 {
        font-size: 0.8rem;
    }
    .contactUnvisibleblock1_1 input {
        font-size: 1rem;
    }
    .formContactMessageTextarea {
        font-size: 1rem;
    }
    .contactUnvisibleblock2 .fac,
    .contactUnvisibleblock2 .inst,
    .contactUnvisibleblock2 .github,
    .contactUnvisibleblock2 .twitter,
    .contactUnvisibleblock2 .linkedin {
        width: 7vw;   
    }
}
@media (max-width: 700px) {
    .contactUnvisibleblock1,
    .contactUnvisibleblock2__icons__h1 {
        font-size: 0.7rem;
    }
    .contactUnvisibleblock2 .picPlus {
        width: 30vw;
    }
    .contactUnvisibleblock2 .fac,
    .contactUnvisibleblock2 .inst,
    .contactUnvisibleblock2 .github,
    .contactUnvisibleblock2 .twitter,
    .contactUnvisibleblock2 .linkedin {
        width: 7vw;   
    }
}
@media (max-width: 600px) {
    .contactUnvisibleblock1 {
        width: 85vw;
        text-align: center;
        margin: auto;
        padding: 1vh 1vw;
        max-width: 85vw;
    }
    .contactUnvisibleblock2 {
        margin: 5vh 0vw;
        padding: 1vh 1vw;
        width: 100%;
    }
    .contactUnvisibleblock1 h1 {
        font-size: 1.2rem;
    }
    .contactFormButtons {
        width: 85vw;
    }
    .contactFormButtons button {
        font-size: 1.2rem;
    }
   
    .contactUnvisibleblock2 {
        padding-bottom: 3vh;
        text-align: center;
        width: 100%;
        border-left: none;
        border-top: rgb(127, 99, 5) 7px solid;
        border-bottom: rgb(127, 99, 5) 7px solid;
    }
    .picPlus__wrap {
        display: none;
    }
    .contactUnvisibleblock2 .picPlus {
        width: 50vw;
        display: none;
    }
    .contactUnvisibleblock2 .fac,
    .contactUnvisibleblock2 .inst,
    .contactUnvisibleblock2 .github,
    .contactUnvisibleblock2 .twitter,
    .contactUnvisibleblock2 .linkedin {
        width: 13vw;   
    }
}
@media(max-width: 300px) {
    .contactUnvisibleblock1,
    .contactUnvisibleblock2__icons__h1 {
        font-size: 0.5rem;
    }
    .contactUnvisibleblock2 .fac,
    .contactUnvisibleblock2 .inst,
    .contactUnvisibleblock2 .github,
    .contactUnvisibleblock2 .twitter,
    .contactUnvisibleblock2 .linkedin {
        width: 14vw;   
    }

}