.menu_main {
    height: 5vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    margin: 0 auto;
    background-color: rgba(3, 10, 10, 0.933);
    border-bottom: snow 1px solid;
    border-top: snow 1px solid;
}

.menu_main li {
    list-style: none;
    color: rgba(24, 158, 158, 0.933);
    font-size: 2.5vh;
    margin: 0 auto;
    padding: 0.5vh 1vw;
    cursor: pointer;
    text-decoration: none;
}

.menuMain__links {
    color: rgba(24, 158, 158, 0.933);
    font-size: 2.5vh;
    margin: 0 auto;
    padding: 0.7vh 1.2vw;
    list-style: none;
    cursor: pointer;
    text-decoration: none;
}
.menuMain__links:hover {
    color: snow;
    background-color: rgba(5, 29, 29, 0.933);
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

@media (max-width: 600px) {
    .menuMain__links {
        padding: 0.7vh 1.5vw;
        letter-spacing: 0.2vw;
        font-size: 1.8vh;
    }
}
@media (max-width: 300px) {
    .menuMain__links {
        padding: 0.8vh 2.2vw;
        letter-spacing: 0.2vw;
        font-size: 1.5vh;
    }
}