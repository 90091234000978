.aboutMain {
    position: relative;
    width: 90vw;
    height: 70vh;
    margin: 2.5vh auto;
    padding: 0vh 0vw;
    background: linear-gradient(rgba(5.1, 23.5, 23.5, 0.933), rgba(5.1, 23.5, 23.5, 0.933)),url(../img/IMGP7571-3.jpg) no-repeat;
    background-size: cover;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: baseline;
}
.aboutMain h1 {
    font-size: 5vh;
}
.aboutMain p {
    font-size: 2vh;
    line-height: 3vh;
}

@media (max-width: 600px) {
    .aboutMain h1 {
        font-size: 4vh;
    }
    .aboutMain p {
        font-size: 1.5vh;
        padding: 0 4vw;
    }
}
@media (max-width: 300px) {
    .aboutMain h1 {
        font-size: 4vh;
    }
    .aboutMain p {
        font-size: 1.5vh;
        padding: 0 2vw;
    }
}